import {ExpirationStrategyTypeEnum} from 'projects/shared/src/lib/enums/expiration-strategy-type.enum';
import {ExpirationStrategyUnitEnum} from 'projects/shared/src/lib/enums/expiration-strategy-unit.enum';
import {expirationStrategyTypeMapper} from 'projects/shared/src/lib/functions/expiration-strategy-type.mapper';
import {NameIdModel} from 'projects/shared/src/lib/models/name-id.model';
import {
  CardDescriptionModel, DescriptionItemModel,
} from 'projects/shared/src/lib/storybook/sims-card/sims-card.component';

import {
  ExternalParameterInterface,
} from '../../../_shared/components/external-parameters/interfaces/external-parameter.interface';
import {PlanZoneSetItemModel} from '../../../availability-zones/models/plan-zone-set-item-model';
import {RenewalIntervalSettingsEnum} from '../../enums/renewal-interval-settings.enum';
import {RenewalIntervalEnum} from '../../enums/renewal-interval.enum';
import {ProductOfferingModel} from '../../plan.model';
import {
  LinkedProductOfferingsInterface,
} from '../../shared/components/linked-offers/linked-product-offerings.interface';
import {recurringPeriodStrategyMapper} from '../../shared/mappers/recurring-period-strategy.mapper';
import {ImsiProviderView} from '../../shared/interfaces/imsi-provider-view.interface';

export interface PoolPlanDescriptionType extends CardDescriptionModel {
  type?: DescriptionItemModel;
  cost?: DescriptionItemModel;
  data?: DescriptionItemModel;
  deviceDataLimit?: DescriptionItemModel;
  sms?: DescriptionItemModel;
  deviceSmsLimit?: DescriptionItemModel;
  activationType?: DescriptionItemModel;
}

export class PoolPlanDto {
  activeSimsAmount?: number;
  assignedSimsAmount?: number;
  currentDataUsage?: number;
  currentSmsUsage?: number;
  dataAllowance?: number;
  dataAllowanceUnit?: string;
  dataLimit?: number;
  dataLimitUnit?: string;
  designatedCustomerAccountId?: string;
  designatedCustomerAccountName?: string;
  effectiveDateTo?: string;
  expirationPeriodType?: ExpirationStrategyUnitEnum;
  expirationPeriodUnits?: number;
  expirationStrategyType: ExpirationStrategyTypeEnum;
  externalParameters?: ExternalParameterInterface[];
  name: string;
  planDescription?: PoolPlanDescriptionType;
  planTitle?: string;
  planType?: string;
  poolType?: string;
  priceCurrency?: string;
  priceValue?: number;
  productOfferingId?: string;
  renewalStrategy?: string;
  recurringType?: RenewalIntervalEnum;
  recurringTypeUnits?: number;
  recurringTypeValue?: number;
  recurringTypeStrategy?: RenewalIntervalSettingsEnum;
  smsAllowance?: number;
  smsLimit?: number;
  totalDataAllowance?: number;
  totalSmsAllowance?: number;
  linkedProductOfferings?: LinkedProductOfferingsInterface[];
  linkedOffersIds?: Record<string, string>;
  offers?: NameIdModel[];
  linkedOffers?: Record<string, string>;
  zoneSets?: {id: string; name: string;}[];
  zoneSetsFull?: PlanZoneSetItemModel[];
  allowedImsiProviders?: ImsiProviderView[];
  isIncludingAccessFee?: boolean;


  constructor(template?: ProductOfferingModel) {
    this.activeSimsAmount = template?.activeSimsAmount;
    this.assignedSimsAmount = template?.assignedSims;
    this.effectiveDateTo = template?.effectiveDateTo ? template.effectiveDateTo.split('[')[0] : null;
    this.expirationStrategyType = expirationStrategyTypeMapper(template.expirationStrategyType, !!template.effectiveDateTo);
    this.expirationPeriodUnits = template.expirationPeriodUnits ? Number.parseInt(template.expirationPeriodUnits, 10) : null;
    this.expirationPeriodType = template.expirationPeriodType;
    this.recurringType = template.recurringType;
    this.recurringTypeStrategy = recurringPeriodStrategyMapper(template.recurringTypeStrategy, template.recurringTypeValue);
    this.recurringTypeValue = template.recurringTypeValue ? parseInt(template.recurringTypeValue, 10) : 0;
    this.recurringTypeUnits = template.recurringTypeUnits ? parseInt(template.recurringTypeUnits, 10) : 0;
    this.designatedCustomerAccountId = template?.designatedCustomerAccountId;
    this.designatedCustomerAccountName = template?.designatedCustomerAccountName;
    this.externalParameters = template?.externalParameters;
    this.name = template?.name;
    this.planType = template?.planType;
    this.poolType = template?.poolType;
    this.priceCurrency = template?.priceCurrency;
    this.priceValue = template?.priceValue ?? 0;
    this.productOfferingId = template?.productOfferingId;
    this.renewalStrategy = template?.renewalStrategy;
    this.planTitle = `${template?.priceValue || 0} ${template?.priceCurrency}`;
    this.dataAllowance = template?.dataAllowance;
    this.dataAllowanceUnit = template?.dataAllowanceUnit;
    this.smsAllowance = template?.smsAllowance;
    this.currentDataUsage = template?.currentDataUsage;
    this.currentSmsUsage = template?.currentSmsUsage;
    this.totalDataAllowance = template?.totalDataAllowance;
    this.totalSmsAllowance = template?.totalSmsAllowance;
    this.dataLimit = template?.dataLimit;
    this.dataLimitUnit = template?.dataLimitUnit;
    this.smsLimit = template?.smsLimit;
    this.planDescription = fillPoolPlanDescription(template);
    this.linkedProductOfferings = template?.linkedProductOfferings;
    if (template?.linkedOffersIds) {
      for (const linkedOffer of template?.linkedOffersIds ?? []) {
        this.linkedOffers[linkedOffer] = 'FIXED';
      }
    }
    this.linkedOffersIds = this.linkedOffers;
    this.zoneSets = template?.zoneSets;
    this.zoneSetsFull = template?.zoneSetsFull;
    this.allowedImsiProviders = template?.allowedImsiProviders;
    this.isIncludingAccessFee = template?.isIncludingAccessFee;
  }
}

const fillPoolPlanDescription = (template: ProductOfferingModel): PoolPlanDescriptionType => ({
  cost: {
    fieldName: 'cost',
    fieldValue: `${template?.priceCurrency} ${template?.priceValue || 0}`,
    valueStyle: {
      'font-size': '16px',
      'font-weight': 'bold',
    },
  },
  type: template?.poolType && {
    fieldName: 'type',
    fieldValue: template?.poolType,
  },
  data: template?.dataAllowance && {
    fieldName: 'data',
    fieldValue: `${template.dataAllowance} ${template.dataAllowanceUnit}`,
  },
  deviceDataLimit: template?.dataLimit && {
    fieldName: 'device-data-limit',
    fieldValue: `${template.dataLimit} ${template.dataLimitUnit}`,
  },
  sms: template?.smsAllowance && {
    fieldName: 'SMS',
    fieldValue: `${template.smsAllowance} SMS`,
  },
  deviceSmsLimit: template?.smsLimit && {
    fieldName: 'device-sms-limit',
    fieldValue: `${template.smsLimit} SMS`,
  },
  activationType: {
    fieldName: 'activation-type',
    fieldValue: template?.renewalStrategy,
  },
});
