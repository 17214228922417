import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {OverlayContainer} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {MatDrawer, MatSidenavContent} from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Angulartics2GoogleTagManager} from 'angulartics2';
import {KeycloakService} from 'keycloak-angular';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

import {fetchAndSetFavicon} from '../../../shared/src/lib/functions/fetch-and-set-ftp-whitelabel-favicon.function';
import {AppHandleLocalStorageService} from '../../../shared/src/lib/services/app-handle-local-storage.service';
import {CurrentAccountModel} from '../../../shared/src/lib/services/current-account/current-account.model';
import {CurrentAccountService} from '../../../shared/src/lib/services/current-account/current-account.service';
import {KeycloakWrapperService} from '../../../shared/src/lib/services/keycloak-wrapper/keycloak-wrapper.service';
import {RoleValidationService} from '../../../shared/src/lib/services/role-validation.service';
import {TranslationSettingsService} from '../../../shared/src/lib/services/translation-settings.service';
import {BdMixins} from '../../../shared/src/lib/utilities/bd-mixins';
import cacheClearEnable from '../assets/config/app.json';
import appVersion from '../assets/config/app.version.json';
import {SimRoute} from '../assets/routings/sim.route';

import {
  SupportMaintenanceNotificationService,
} from './_shared/components/support-maintenance-notification/support-maintenance-notification.service';
import {SimProjectProfileEnum} from './_shared/enums/sim-project-profile.enum';
import {SimRole} from './_shared/enums/sim-roles.enum';
import {SidenavContent} from './_shared/models/sidenav.content';
import {SimLayoutService} from './_shared/services/sim-layout.service';
import {RoutingConfigService} from './routing-config.service';

@Component({
  selector: 'sim-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BdMixins.hasSubs(class {}) implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('floDocumentViewer', {read: TemplateRef, static: true}) documentViewerTmpl!: TemplateRef<any>;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('sidenavContent') sidenavContentRef: MatSidenavContent;

  envNameEnum = SimProjectProfileEnum;
  showEcwidStore = null;
  shopRouteMap: Record<string, string> = {
    '/account': 'account',
    '/category': 'category',
    '/cart': 'cart',
  };
  protected readonly simRole = SimRole;
  expandMode = true;
  isHandset = false;
  isUserAccessValidationPassed = false;
  isOptionalViewActive: boolean;
  isSideBarClosed: boolean;
  userId: string;
  userData: CurrentAccountModel;
  switchHeader$: Subject<TemplateRef<any> | null> = new Subject<TemplateRef<any> | null>();
  isStandalonePage: boolean;
  ordersRole: SimRole = SimRole.ORDERS_MENU;
  private readonly appVersion: string = appVersion.version;
  private readonly cacheClearEnable: boolean = cacheClearEnable.isCacheClearAvailable;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly translateSettings: TranslationSettingsService,
    private readonly supportMaintenanceNotificationService: SupportMaintenanceNotificationService,
    private readonly keycloakService: KeycloakService,
    private readonly keycloakWrapperService: KeycloakWrapperService,
    private readonly keycloakValidatorService: RoleValidationService,
    private readonly translateService: TranslateService,
    private readonly layoutService: SimLayoutService,
    private readonly handleStorageService: AppHandleLocalStorageService,
    private readonly currentAccountService: CurrentAccountService,
    private readonly hostER: ElementRef,
    private readonly overlayContainer: OverlayContainer,
    private readonly angulartics2GoogleAnalytics: Angulartics2GoogleTagManager,
    readonly routingConfigService: RoutingConfigService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
  ) {
    super();
    this.startGoogleAnalytics();
  }

  @HostListener('document:visibilitychange') visibilitychange(): void {
    if (document.visibilityState === 'visible' && this.keycloakService.isTokenExpired()) {
      this.keycloakService.updateToken().then((updated: boolean) => {
        if (!updated) {
          this.keycloakWrapperService.logout();
        }
      });
    }
  }

  ngOnInit(): void {
    this.defineMainTheme();
    this.checkAccessPermission();
    this.routingConfigService.envName$.subscribe((envName: SimProjectProfileEnum): void => {
      if (envName === SimProjectProfileEnum.TELIT) {
        return;
      }
      fetchAndSetFavicon(this.document.domain, this.http, this.document);
    });
    this.clearLocalStorage();
    this.isSideBarClosed = false;
    this.isStandalonePage = window.location.hash.includes('(standalone:');
    this.toggleFlex();
    this.translateSettings.setSavedLang();
    this.subscribeForBreakpointHandset();
    this.subscribeForNavigationEnd();
  }

  ngAfterViewInit(): void {
    this.layoutService.listenToSidenavContentScroll(this.sidenavContentRef);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  closeSidenav(): void {
    if (this.isHandset && this.drawer.opened) {
      this.drawer.close();
    }
  }

  toggleExpandMode(): void {
    this.expandMode = !this.expandMode;
    this.initWindowResizeEvent();
  }

  toggleOptionalView(isViewActive: boolean): void {
    this.isOptionalViewActive = isViewActive;
    this.switchHeader$.next(isViewActive ? this.documentViewerTmpl : null);
  }

  toggleSideNav(isToggle: boolean): void {
    this.isSideBarClosed = isToggle;
    this.initWindowResizeEvent();
  }

  private toggleFlex(): void {
    this.subs$.push(
      this.layoutService.sidenavContentFillHeight$
        .subscribe(((classes: {[prop: string]: {class: string;};}) => (sidenavContent: SidenavContent) => {
          const cssClassesAdded: string[] = [];
          const cssClassesRemoved: string[] = [];

          Object.keys(classes).forEach((prop: string) => {
            if (sidenavContent[prop as keyof SidenavContent]) {
              cssClassesAdded.push(classes[prop].class);
            } else {
              cssClassesRemoved.push(classes[prop].class);
            }
          });
          if (cssClassesAdded.length) {
            this.hostER.nativeElement.classList.add(...cssClassesAdded);
          }
          if (cssClassesRemoved.length) {
            this.hostER.nativeElement.classList.remove(...cssClassesRemoved);
          }
        })({
          isFillHeight: {class: 'fill-height'},
          withoutMainScroll: {class: 'without-scroll'},
          withoutPaddingSide: {class: 'without-padding'},
        })),
    );
  }

  initWindowResizeEvent(): void {
    // TODO This magic appears here because of wrong usage sidenav component. Here we need to emit resize event for recalculate dashboards with sidenav content width change
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  private subscribeForBreakpointHandset(): void {
    this.subs$.push(
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      ).subscribe(result => {
        this.expandMode = !result.matches;
        this.isSideBarClosed = result.matches;
      }),
    );
  }

  private subscribeForNavigationEnd(): void {
    this.subs$.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe((e: NavigationEnd) => {
        this.closeSidenav();
        this.onRouteChangeShowEcwidStore(e.url);
      }),
    );
  }

  private checkAccessPermission(): void {
    if (this.keycloakValidatorService.validateRole('ROLE_SELF_CARE_ONLY')) {
      alert(this.translateService.instant('access-denied'));
      this.keycloakWrapperService.logout();
    } else {
      this.isUserAccessValidationPassed = true;
    }
  }

  private clearLocalStorage(): void {
    const newAppVersion: boolean = this.checkAppNewVersion();

    this.updateUserIdIntoLocalStorage();
    if (newAppVersion || this.cacheClearEnable) {
      this.handleStorageService.clearData();
    }
  }

  checkAppNewVersion(): boolean {
    const currentAppVersion: string = this.handleStorageService.getData('appVersion');

    if (!currentAppVersion) {
      this.handleStorageService.saveData('appVersion', this.appVersion);

      return false;
    }
    if (currentAppVersion !== this.appVersion) {
      return true;
    }
  }

  private updateUserIdIntoLocalStorage(): void {
    this.subs$.push(this.currentAccountService.get()
      .subscribe(res => {
        if (!res) {
          console.error('User is undefined');

          return;
        }
        this.angulartics2GoogleAnalytics.setUsername(res.name);
        this.handleStorageService.saveData('recentUserId', res.userId);
      }),
    );
  }

  private defineMainTheme(): void {
    document.body.classList.add('default-theme');
    this.overlayContainer.getContainerElement().classList.add('default-theme');
  }

  private onRouteChangeShowEcwidStore(url: string): void {
    if (url === `/${SimRoute.ORDER}` || url === `/${SimRoute.PRODUCTS}` || url === `/${SimRoute.SHOP_CART}`) {
      window['Ecwid']?.openPage(this.shopRouteMap[url]);
      this.showEcwidStore = true;
    } else {
      this.showEcwidStore = false;
    }
  }

  private startGoogleAnalytics(): void {
    this.angulartics2GoogleAnalytics.startTracking();
  }
}
