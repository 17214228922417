import {
  DeviceManagementRouteEnum,
} from '../../../../../modules/device-management/src/lib/_shared/enums/device-management-route.enum';
import {ROLE} from '../../../../../shared/src/lib/enums/role.enum';
import {NavItemModel} from '../../../../../shared/src/lib/models/nav-item.model';
import {FloIcons} from '../../../../../shared/src/lib/services/custom-icons-registration.service';
import {SimRoute} from '../../../assets/routings/sim.route';
import {AvailabilityZonesRouteEnum} from '../../availability-zones/availability-zones-route.enum';
import {BillingRoute} from '../../billing/billing.route';
import {ResourcesRoute} from '../../resources/resources.route';
import {SettingsRoute} from '../../settings/settings.route';
import {SupportRoute} from '../../support/support.route';
import {SimProjectProfileEnum} from '../enums/sim-project-profile.enum';
import {SimRole} from '../enums/sim-roles.enum';

import {TELIT_SIDENAV_ITEMS} from './telit-sidenav-items';

export const SIM_SIDENAV_ITEMS: NavItemModel[] = [
  {
    title: 'products',
    iconClass: FloIcons.floOrderShop,
    svgIcon: true,
    route: `/${SimRoute.PRODUCTS}`,
    authority: SimRole.ORDERS_MENU,
    rootCategory: 'main',
  },
  {
    title: 'my-orders',
    iconClass: FloIcons.floMyOrders,
    svgIcon: true,
    route: `/${SimRoute.ORDER}`,
    authority: SimRole.ORDERS_MENU,
    rootCategory: 'main',
  },
  {
    title: 'dashboard',
    iconClass: FloIcons.floDashboard,
    svgIcon: true,
    route: `/${SimRoute.DASHBOARD}`,
    authority: SimRole.DASHBOARD_MENU,
    rootCategory: 'main',
  },
  {
    title: 'sims',
    iconClass: FloIcons.floSims,
    svgIcon: true,
    route: `/${SimRoute.RESOURCES}/${ResourcesRoute.SIMS}`,
    authority: SimRole.SIMS_MENU,
    rootCategory: 'main',
  },
  {
    title: 'sims-provisioning',
    iconClass: FloIcons.floSimCenter,
    svgIcon: true,
    route: `/${SimRoute.SIMS_PROVISIONING}`,
    authority: SimRole.SIMS_PROVISIONING_MENU,
    rootCategory: 'main',
  },
  {
    title: 'batch-operations',
    iconClass: FloIcons.floBatch,
    svgIcon: true,
    rootCategory: 'main',
    route: `/${SimRoute.BATCH_OPERATIONS}`,
    authority: SimRole.BATCH_OPERATIONS_MENU,
  },
  {
    title: 'plans',
    iconClass: FloIcons.floBilling,
    svgIcon: true,
    authority: [SimRole.REGULAR_PLANS_MENU, SimRole.POOL_PLANS_MENU, SimRole.AVAILABILITY_ZONE_MENU, SimRole.INVOICES_MENU],
    route: `/${SimRoute.PLANS}`,
    rootCategory: 'main',
    children: [
      {
        title: 'regular-plans',
        iconClass: '',
        route: `/${SimRoute.PLANS}/${BillingRoute.BILLING_PLANS}`,
        authority: SimRole.REGULAR_PLANS_MENU,
      },
      {
        title: 'pool-plans',
        iconClass: '',
        route: `/${SimRoute.PLANS}/${BillingRoute.POOL_PLANS}`,
        authority: SimRole.POOL_PLANS_MENU,
      },
      {
        title: AvailabilityZonesRouteEnum.availabilityZones,
        route: `${SimRoute.AVAILABILITY_ZONES}`,
        authority: SimRole.AVAILABILITY_ZONE_MENU,
      },
      {
        title: 'invoices',
        iconClass: '',
        route: `/${SimRoute.PLANS}/${BillingRoute.INVOICES}`,
        authority: SimRole.INVOICES_MENU,
      },
    ],
  },
  {
    title: 'device-management',
    iconClass: FloIcons.deviceManagement,
    svgIcon: true,
    route: `/${SimRoute.DEVICE_MANAGEMENT}`,
    rootCategory: 'main',
    authority: SimRole.DM_MENU,
    children: [
      {
        title: 'devices',
        route: `/${SimRoute.DEVICE_MANAGEMENT}/${DeviceManagementRouteEnum.DEVICES}`,
        authority: null,
      },
      {
        title: 'device-templates',
        route: `/${SimRoute.DEVICE_MANAGEMENT}/${DeviceManagementRouteEnum.DEVICE_TEMPLATES}`,
        authority: null,
      },
      {
        title: 'device-templates',
        route: `/${SimRoute.DEVICE_MANAGEMENT}/${DeviceManagementRouteEnum.BATCH_HISTORY}`,
        authority: null,
      },
    ],
  },
  {
    title: 'my-sub-accounts',
    iconClass: FloIcons.floSubAccounts,
    svgIcon: true,
    authority: SimRole.SUB_ACCOUNTS_MENU,
    route: `/${SimRoute.SUB_ACCOUNTS}`,
    rootCategory: 'main',
  },
  {
    title: 'providers',
    iconClass: FloIcons.floServiceProviders,
    svgIcon: true,
    route: `/${SimRoute.SERVICE_PROVIDERS}`,
    authority: SimRole.PROVIDERS_MENU,
    rootCategory: 'main',
  },
  {
    title: SimRoute.RATES_AND_FEES,
    iconClass: FloIcons.floConnectivity,
    svgIcon: true,
    authority: SimRole.CONNECTIVITY_RATES_MENU,
    route: `/${SimRoute.RATES_AND_FEES}`,
    rootCategory: 'main',
  },
  {
    title: 'events',
    iconClass: FloIcons.floEvents,
    svgIcon: true,
    route: `/${SimRoute.DWH}`,
    authority: SimRole.EVENTS_MENU,
    rootCategory: 'main',
  },
  {
    title: 'reports',
    iconClass: FloIcons.floReports,
    svgIcon: true,
    route: `/${SimRoute.REPORTS}/`,
    authority: SimRole.REPORTS_MENU,
    rootCategory: 'main',
  },
  {
    title: 'rules-and-notifications',
    iconClass: FloIcons.ra,
    svgIcon: true,
    route: `/${SimRoute.RULES_AND_NOTIFICATIONS}`,
    authority: [SimRole.RULE_MENU, SimRole.NOTIFICATION_MENU],
    rootCategory: 'main',
    children: [
      {
        title: 'ra-rules',
        route: `/${SimRoute.RA}`,
        authority: SimRole.RULE_MENU,
      },
      {
        title: 'notifications',
        route: `/${SimRoute.NOTIFICATIONS}`,
        authority: SimRole.NOTIFICATION_MENU,
      },
    ],
  },
  {
    title: 'order-sims',
    iconClass: FloIcons.floBasketCart,
    svgIcon: true,
    authority: SimRole.ORDER_SIMS_MENU,
    route: `/${SimRoute.ORDER_SIM}`,
    rootCategory: 'main',
  },
  {
    title: 'billing-and-invoices',
    iconClass: FloIcons.floDocument,
    svgIcon: true,
    route: `/${SimRoute.BILLING_AND_INVOICES}`,
    authority: SimRole.BILLING_INVOICES_MENU,
    rootCategory: 'main',
  },
  {
    title: 'support',
    iconClass: FloIcons.floSupport,
    svgIcon: true,
    route: `/${SupportRoute.ROOT}`,
    authority: [SimRole.SUPPORT_MY_TICKETS, SimRole.SUPPORT_MY_SUB_ACCOUNTS_TICKETS],
    rootCategory: 'main',
  },
  {
    title: 'settings',
    iconClass: FloIcons.floSettings,
    svgIcon: true,
    route: `/${SettingsRoute.root}`,
    authority: [
      ROLE.SUPER_ADMIN_IMPERSONATOR,
      SimRole.USERS_SETTINGS,
      SimRole.LABELS_SETTINGS,
      SimRole.TERMS_OF_USE,
      SimRole.GL_CODES,
      SimRole.AUDIT_TRAIL,
      SimRole.SETTINGS_EXTERNAL_PARAMS,
      SimRole.SMS_CONTENT,
      SimRole.BILLING_INVOICE_SETTINGS,
      SimRole.DEVICE_OPERATIONS,
    ],
    rootCategory: 'main',
  },
];

export const SIDENAV_ITEMS_PROFILES: Record<SimProjectProfileEnum, NavItemModel[]> = {
  FLO_CONNECT: SIM_SIDENAV_ITEMS,
  TELIT: TELIT_SIDENAV_ITEMS,
};
