import {OverlayContainer} from '@angular/cdk/overlay';
import {HttpClient} from '@angular/common/http';
import {
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ApolloQueryResult} from '@apollo/client';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {KeycloakService} from 'keycloak-angular';
import {SettingsSource} from 'projects/shared/src/lib/models/app.settings.model';
import {SimModel} from 'projects/shared/src/lib/models/sim.model';
import {CurrentAccountModel} from 'projects/shared/src/lib/services/current-account/current-account.model';
import {TranslationsService} from 'projects/shared/src/lib/services/translations.service';
import {Observable, Subject} from 'rxjs';
import {debounceTime, filter, map, take, takeUntil} from 'rxjs/operators';

import {CustomJwtHelperService} from '../../../../bss/src/app/_shared/services/custom-jwt-helper.service';
import {AppSettingsService} from '../../../../shared/src/lib/services/app-settings.service';
import {CurrentAccountService} from '../../../../shared/src/lib/services/current-account/current-account.service';
import {ImpersonateService} from '../../../../shared/src/lib/services/impersonate/impersonate.service';
import {KeycloakWrapperService} from '../../../../shared/src/lib/services/keycloak-wrapper/keycloak-wrapper.service';
import {RoleValidationService} from '../../../../shared/src/lib/services/role-validation.service';
import {TranslationSettingsService} from '../../../../shared/src/lib/services/translation-settings.service';
import {SimRoute} from '../../assets/routings/sim.route';
import {UsersSelectDialogComponent} from '../_shared/components/users-select-dialog/users-select-dialog.component';
import {SimProjectProfileEnum} from '../_shared/enums/sim-project-profile.enum';
import {SimRole} from '../_shared/enums/sim-roles.enum';
import {EcwidInitializationService} from '../_shared/services/ecwid-initialization.service';
import {DEFAULT_BILLING_PLANS_STATE, SetBillingPlansState} from '../billing/billing-plans/billing-plans.state';
import {DEFAULT_POOL_PLANS_STATE, SetPoolPlansState} from '../billing/pool-plans/pool-plans.state';
import {EShopProfile} from '../order/gql/e-shop-profile.query';
import {ResourcesRoute} from '../resources/resources.route';

import {RoutingConfigService} from '../routing-config.service';

import {ToolbarSearchSimQuery, ToolbarSearchSimQueryResponse} from './gql/toolbar-search-sim.query';

@Component({
  selector: 'sim-portal-toolbar',
  templateUrl: './portal-toolbar.component.html',
  styleUrls: ['./portal-toolbar.component.scss'],
})
export class PortalToolbarComponent implements OnInit, OnDestroy {
  @Input() isHeadset: boolean;
  @Input() switchHeader$: Observable<TemplateRef<any> | null>;
  @Input() isStoreOpen: boolean;

  @Output() toggleDrawer: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('header', {read: ViewContainerRef, static: true}) customHeader: ViewContainerRef;
  @ViewChild('defaultHeader', {read: TemplateRef, static: true}) defaultHeaderTpl!: TemplateRef<any>;
  @ViewChild('searchInput') searchInput: ElementRef;

  aboutLink: string;
  avatarPath = '';
  envName: SimProjectProfileEnum;
  envNameEnum = SimProjectProfileEnum;
  hostname: string;
  isImpersonated: boolean;
  impersonationAllowed: boolean;
  impersonationSessionTimeout: number;
  isPending: boolean;
  langs$: Observable<string[]>;
  searchControl: FormControl<string>;
  searchProperties = [
    'iccid',
    'imsi',
    'msisdn',
    'alias',
    'eid',
    'imeisv',
    'imei',
  ];
  searchResult: Record<string, SimModel[]> = {};
  supportCenterLink: string;
  termsOfUseLink: string;
  termsOfUseUserPath: string | undefined;
  themes: any[] = [{
    name: 'default-theme',
    color: 'secondary',
  }, {
    name: 'dark-theme',
    color: 'primary',
  }];
  ordersRole = SimRole.ORDERS_MENU;
  userData: CurrentAccountModel;
  userManualFileName: string;
  userManualUrl: string;

  private readonly destroy$: Subject<void> = new Subject<void>();
  private headerContainerRef: EmbeddedViewRef<TemplateRef<any>>;
  private userManualFileNameExt: string;

  constructor(
    private readonly store: Store,
    private readonly currentAccountService: CurrentAccountService,
    private readonly routingConfigService: RoutingConfigService,
    private readonly ecwidService: EcwidInitializationService,
    private readonly overlayContainer: OverlayContainer,
    private readonly keycloakService: KeycloakService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly renderer2: Renderer2,
    private readonly activatedRoute: ActivatedRoute,
    private readonly http: HttpClient,
    private readonly simSearchQuery: ToolbarSearchSimQuery,
    private readonly translateSettings: TranslationSettingsService,
    private readonly translate: TranslateService,
    private readonly jwtHelperService: CustomJwtHelperService,
    private readonly impersonateService: ImpersonateService,
    private readonly appSettings: AppSettingsService,
    private readonly translationsService: TranslationsService,
    private readonly keycloakValidatorService: RoleValidationService,
    private readonly keycloakWrapperService: KeycloakWrapperService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.hostname = location.hostname;
    this.createSearchControl();
    this.subscribeToSearchChanges();
    this.getLangs();
    this.initHeader();
    this.routingConfigService.envName$.subscribe((envName: SimProjectProfileEnum) => {
      if (envName === SimProjectProfileEnum.TELIT) {
        this.envName = envName;

        return;
      }
      this.subscribeToAccount();
      this.initLinks();
      this.initImpersonation();
      if (this.keycloakValidatorService.validateRole(this.ordersRole)) {
        this.initCartButton();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeLang(lang: string): void {
    this.translateSettings.setAndSaveLangInner(lang);
  }

  changeTheme(theme: string): void {
    this.removeThemes(document.body);
    this.removeThemes(this.overlayContainer.getContainerElement());
    document.body.classList.add(theme);
    this.overlayContainer.getContainerElement().classList.add(theme);
  }

  createSearchControl(): void {
    this.searchControl = this.fb.control<string>(null, [
      Validators.required,
      Validators.minLength(1),
    ]);
  }

  fetchSimsCardsForSearch(search: string): void {
    this.searchResult = {};
    this.isPending = true;

    search = search.trim();
    this.getSimsQuery(search).subscribe(({data}) => {
      this.fetchSimsCardsForSearchSuccess(data?.searchSims?.list, search);
    });
  }

  fetchSimsCardsForSearchSuccess(simList: SimModel[], search: string): void {
    const searchResult = {};

    this.isPending = false;
    simList?.forEach((sim: SimModel) => {
      this.searchProperties.forEach(property => {
        if (property === 'imsi' || property === 'msisdn') {
          this.pushImsiMsisdnPairsResults(searchResult, sim, property, search);
        }
        if (property === 'iccid') {
          this.pushIccidResults(searchResult, sim, property, search);
        }

        if (!sim[property]?.toString().includes(search.toString())) {
          return;
        }
        if (!(property in searchResult)) {
          searchResult[property] = [];
        }
        searchResult[property].push(sim);
      });
    });
    this.searchResult = searchResult;
  }

  getLangs(): void {
    this.langs$ = this.translationsService.getLangsList('sim');
  }

  getSimsQuery(textSearch: string): Observable<ApolloQueryResult<ToolbarSearchSimQueryResponse>> {
    const params = {
      pagination: {
        pageSize: 25,
        pageIndex: 0,
      },
      textSearch,
    };

    return this.simSearchQuery.fetch({params}).pipe(
      takeUntil(this.destroy$),
    );
  }

  logout(): void {
    this.store.dispatch([
      new SetBillingPlansState(DEFAULT_BILLING_PLANS_STATE),
      new SetPoolPlansState(DEFAULT_POOL_PLANS_STATE),
    ]);
    this.keycloakWrapperService.logout();
  }

  navigateToSim($event: MatAutocompleteSelectedEvent): void {
    this.searchResult = {};
    this.searchControl.reset();
    this.isPending = false;
    this.router.navigate([SimRoute.RESOURCES, ResourcesRoute.SIMS, $event.option.value, ResourcesRoute.SIM_INFO]);
  }

  onDownloadUserManualClick(): void {
    const a = document.createElement('a');

    a.href = `file-storage/download/user-manual/${this.hostname}.${this.userManualFileNameExt}`;
    a.download = this.userManualFileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  openDocumentViewerWindow(path: string, docName?: string): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.router.navigate([{outlets: {optional: SimRoute.FLO_DOCUMENT_VIEWER}}], {
        queryParams: {urlPath: path, docName},
        queryParamsHandling: 'merge',
      });
    });
  }

  removeThemes(node: HTMLElement): void {
    node.classList.forEach(value => {
      const match = value.match(/.*-theme$/g);
      const themes = match ? match.map(val => val.toString()) : [];

      node.classList.remove(...themes);
    });
  }

  subscribeToAccount(): void {
    this.currentAccountService.getUpdatableAccountSettings()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.userData = res;
        this.defineTermsOfUseLink(res);
      });
  }

  subscribeToSearchChanges(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.searchControl.valid),
        takeUntil(this.destroy$),
      )
      .subscribe(value => {
        this.fetchSimsCardsForSearch(value);
      });
  }

  impersonate(): void {
    this.dialog.open(UsersSelectDialogComponent, {
      width: '500px',
      data: {
        title: 'login-on-behalf',
      },
    }).afterClosed().pipe(filter(res => !!res))
      .subscribe((userId: string) => {
        this.impersonateService.impersonate(userId).subscribe(() => {
          window.location.reload();
        });
      });
  }

  unImpersonate(): void {
    this.impersonateService.unImpersonate();
    window.location.reload();
  }

  clearSearchField(): void {
    this.searchControl.reset();
    this.searchResult = {};
    this.isPending = false;
  }

  private composeTermsOfUseLink(res: CurrentAccountModel): string {
    const accountId: string = res.uuId;
    const fileName: string = res?.termsOfUsePath?.split('/')?.pop();

    if (!fileName) {
      return;
    }

    return `file-storage/download/terms/${accountId}/${fileName}`;
  }

  private defineTermsOfUseLink(res: CurrentAccountModel): void {
    this.termsOfUseUserPath = res?.termsOfUsePath;
    if (this.userData?.avatarPath) {
      this.avatarPath = this.userData.avatarPath;
    }

    if (this.userData?.isRoot) {
      this.termsOfUseLink = this.composeTermsOfUseLink(res);
    } else {
      this.keycloakService.getToken().then(token => {
        const termsUrl: string = this.jwtHelperService.decode(token)?.termsUrl?.split('/').slice(-2)?.join('/');

        if (termsUrl) {
          this.termsOfUseLink = `file-storage/download/terms/${termsUrl}`;
        }
      });
    }
  }

  private initHeader(): void {
    this.headerContainerRef = this.customHeader.createEmbeddedView(this.defaultHeaderTpl);
    if (this.switchHeader$) {
      this.switchHeader$
        .pipe(
          takeUntil(this.destroy$),
          map((header: TemplateRef<any> | null): TemplateRef<any> => header || this.defaultHeaderTpl),
        )
        .subscribe((header: TemplateRef<any>) => {
          this.customHeader.clear();
          this.headerContainerRef.destroy();
          this.headerContainerRef = this.customHeader.createEmbeddedView(header);
        });
    }
  }

  private pushIccidResults(searchResult: Record<string, SimModel | any>, sim: SimModel, property: string, search: string): void {
    sim.identifiers.forEach(pair => {
      if (pair[property]?.toString().includes(search)) {
        if (!(property in searchResult)) {
          searchResult[property] = [];
        }
        const simToShow: SimModel = {...sim};

        simToShow[property] = pair[property];
        searchResult[property].push(simToShow);
      }
    });
  }

  private pushImsiMsisdnPairsResults(searchResult: Record<string, SimModel | any>, sim: SimModel, property: string, search: string): void {
    sim.identifiers.forEach(identifier => {
      identifier.imsiMsisdnPairs.forEach(pair => {
        if (pair[property]?.toString().includes(search)) {
          if (!(property in searchResult)) {
            searchResult[property] = [];
          }
          const simToShow = {...sim};

          simToShow[property] = pair[property];
          searchResult[property].push(simToShow);
        }
      });
    });
  }

  private initLinks(): void {
    this.appSettings.supportLink$.subscribe(supportLink => {
      this.supportCenterLink = supportLink;
    });
    this.appSettings.userManual$.subscribe(userManual => {
      switch (userManual?.type) {
        case SettingsSource.FILE:
          this.userManualFileNameExt = userManual.file_ext || 'pdf';
          this.http.get(`file-storage/is-file-exists/user-manual/${this.hostname}.${this.userManualFileNameExt}`)
            .pipe(take(1), filter((exists: boolean) => exists))
            .subscribe(() => {
              this.userManualFileName = `${userManual.value}.${this.userManualFileNameExt}`;
            });
          break;
        case SettingsSource.URL:
          this.userManualUrl = userManual.value;
          break;
        default:
      }
    });

    this.http.get(`file-storage/is-file-exists/about/${this.hostname}.pdf`)
      .pipe(take(1), filter((exists: boolean) => exists)).subscribe(() => {
      this.aboutLink = `file-storage/download/about/${this.hostname}.pdf`;
    });
  }

  private initImpersonation(): void {
    this.isImpersonated = this.impersonateService.isImpersonated();
    this.impersonationAllowed = this.impersonateService.impersonationAllowed();

    if (this.isImpersonated) {
      this.impersonationSessionTimeout = this.impersonateService.getSessionTimeout();
      if (this.impersonationSessionTimeout <= 0) {
        this.unImpersonate();
      }

      const intervalID = setInterval(() => {
        this.impersonationSessionTimeout = this.impersonateService.getSessionTimeout();
        if (this.impersonationSessionTimeout <= 0) {
          clearInterval(intervalID);
          this.unImpersonate();
        }
      }, 1000);
    }
  }

  private initCartButton(): void {
    const currentLang = this.translate.currentLang ?? this.translate.defaultLang;

    this.ecwidService.getEShopProfile().pipe(takeUntil(this.destroy$), filter
    (res => !!res)).subscribe((info: EShopProfile) => {
      const storeId: string = info.storeId;
      const script = this.renderer2.createElement('script');
      const date = new Date().toISOString().split('T')[0];

      script.setAttribute('data-cfasync', 'false');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('id', 'ecwid-starter-cart-script');
      script.setAttribute('charset', 'utf-8');
      script.setAttribute(
        'src',
        `https://app.business.shop/script.js?${storeId}&lang=${currentLang}&data_platform=code&data_date=${date}`,
      );

      script.onload = this.injectCartScript();
      this.renderer2.appendChild(document.getElementById('ecwid-cart-button-container'), script);
    });
    document.getElementById('ecwid-button-wrapper').addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.router.navigate([`/${SimRoute.SHOP_CART}`]);
    }, true);
  }

  private injectCartScript(): () => void {
    return (): void => {
      const cartScriptInit = document.createElement('script');

      cartScriptInit.setAttribute('type', 'text/javascript');
      cartScriptInit.text = `Ecwid.init();`;
      this.renderer2.appendChild(document.getElementById('ecwid-cart-button-container'), cartScriptInit);
    };
  }
}
