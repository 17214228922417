import {CommonModule} from '@angular/common';
import {AfterViewInit, Component, Input, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KeycloakService} from 'keycloak-angular';

import {SimRoute} from '../../assets/routings/sim.route';
import {EcwidInitializationService} from '../_shared/services/ecwid-initialization.service';

@Component({
  selector: 'sim-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class OrderComponent implements AfterViewInit {
  @Input() isPublic: boolean = false;
  @Input() eShopId: string;

  storeId: string;

  constructor(
    private readonly renderer2: Renderer2,
    private readonly ecwidService: EcwidInitializationService,
    private readonly translate: TranslateService,
    private readonly keycloakService: KeycloakService,
  ) {}

  ngAfterViewInit(): void {
    if (this.isPublic) {
      this.initShop(this.eShopId, '');
    } else {
      this.ecwidService.getEShopProfile().subscribe(res => {
        if (res?.storeId) {
          this.initShop(res.storeId, res.data);
        }
      });
    }
  }

  private initShop(storeId: string, data: string): void {
    this.storeId = storeId;
    window['ecwid_sso_profile'] = data;
    this.integrateEcwidEmbeddedCode(this.storeId);
  }

  private integrateEcwidEmbeddedCode(storeId: string): void {
    // TODO Resolve hash fragment issue in ecwid store
    this.ecwidRouteRemoveExtraHash();
    const currentLang = this.translate.currentLang ?? this.translate.defaultLang;
    const script = this.renderer2.createElement('script');
    const date = new Date().toISOString().split('T')[0];

    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'ecwid-starter-script');
    script.setAttribute('charset', 'utf-8');
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute(
      'src',
      `https://app.ecwid.com/script.js?${storeId}&lang=${currentLang}&data_platform=code&data_date=${date}`,
    );

    script.onload = this.injectEcwidProductBrowser(storeId);
    this.renderer2.appendChild(document.body, script);
  }

  private injectEcwidProductBrowser(storeId: string): () => void {
    return (): void => {
      (window as any).xProductBrowser('categoriesPerRow=3', 'views=grid(20,3)', 'categoryView=grid', 'searchView=list', `id=my-store-${storeId}`);

      window['Ecwid'].OnPageLoaded.add(page => {
        if (page.type === 'CART') {
          document.getElementById('ec-cart-email-input')?.setAttribute('disabled', '');
          if (!this.keycloakService.isLoggedIn()) {
            document.getElementById('form-control__checkbox--agree')?.setAttribute('disabled', '');
            document.querySelector('.ec-cart__buttons .form-control__button')?.addEventListener('click', () => {
              window.location.assign(`${window.location.origin}/#/(standalone:${SimRoute.REGISTRATION})`);
            });
          }
        }
      });

      if (window.location.hash === `#/(standalone:${SimRoute.PRODUCTS_PUBLIC})`) {
        window.addEventListener('beforeunload', (): void => {
          history.pushState({}, '', `${window.location.origin}/#/(standalone:${SimRoute.PRODUCTS_PUBLIC})`);
        });
      }
    };
  }

  private ecwidRouteRemoveExtraHash(): void {
    const script = this.renderer2.createElement('script');

    script.text = 'window.ec = window.ec || {};' +
      'window.ec.config = window.ec.config || {};' +
      'window.ec.config.storefrontUrls = window.ec.config.storefrontUrls || {};' +
      'window.ec.config.storefrontUrls.cleanUrls = true;' +
      'window.ec.config.storefrontUrls.queryBasedCleanUrls = true;';
    this.renderer2.appendChild(document.head, script);
  }
}
